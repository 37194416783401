.containerCapaMusic{
  min-width: 300px;
  height: 190px;
  margin-right: 8px;
  margin-top: 8px;
  background-color: #111;
  /* background-color: rgba(111, 111, 111, 0.5); */
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.capaFoto{
  width: 10px;
  height: 10px;
}

.containerCapaMusic:hover{
  background-color: #4f4f4f;
}

.contentCapa{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}


.containerCapaMusic img{
  width: 80%;
  height: 100%;
  object-fit: cover;
  transition: all ease-in-out 0.2s;
  border-radius: 10px;
}

.breve{
  text-align: center;
  color: red;
  margin-top: -40px;
  margin-bottom: 20px;
}
