.containerCapaMusic{
  width: 100px;
  height: 100px;
  margin-right: 8px;
  margin-top: 8px;
  cursor: pointer;
  transition: all ease-in-out 300ms;
}

.contentCapa{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentCapa p{
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.containerCapaMusic img{
  width: 100%;
  height: 90%;
  object-fit: cover;
  transition: all ease-in-out 0.2s;
}

.containerCapaMusic:hover{
  transform: scale(1.1);
}

.ContainerArrows{
  /* opacity: 0; */
  transition: all ease-in-out 0.5s;
}

.ContainerArrows:hover{
  opacity: 1;
}