*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-tap-highlight-color: transparent; 
}
body{
  background-color: #222;
}

.banner{
  width: 100%;
  height: 70vh;
  object-fit: cover;
  /* margin-top: -10px; */
}

.headerApp{
  width: 100%;
  height: 50px;
  /* background-color: #111; */
  background-color: rgba(1, 1, 1, 0.5); 
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -20px;
  position: fixed;
  top:0;
}

.headerApp p{
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.containerArtistas{
  margin-top: -100px;
}

.contentCardMusic{

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding-left: 20px;
  padding-right: 20px;

  margin: 4px, 4px;
  padding: 4px;

  width: inherit;

  transition: all ease-in-out 0.5s;

  overflow-x: hidden;
  overflow-y: hidden;
}

.artista{
  color:#fff;
  margin-left:6px;
  font-weight:500;
  margin-top: 20px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  background: #222;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content {
  width: 90%;
  max-width: 500px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  outline: none;
}

button {
  padding: 5px;
  font-size: large;
  float: right;
  cursor: pointer;
  border-radius: 10px;
  padding: 0 16px;
  border:0;
  margin-right: 16px;
  margin-top: 16px;
  color: "#fff";
  background-color: #cdcdcd;
  transition: all ease-in-out 0.2s;
}
button:hover{
  background-color: #222;
}

p {
  margin-top: 8px;
}


::-webkit-scrollbar-track {
  background-color: #F4F4F4;
}
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
  background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
  background-color:rgba(4,120,87,1)
}

h4{
  color:#fff
}